import FullCalendar from '@fullcalendar/vue3';
import frLocale from '@fullcalendar/core/locales/fr';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayjs from 'dayjs';
import SessionModal from './SessionModal.vue';

let fcApi = null;

export default {
    components: {
        FullCalendar,
        SessionModal,
    },
    data() {
        return {
            calendarOptions: {
                plugins: [timeGridPlugin, interactionPlugin],
                locale: frLocale,
                height: 'auto',
                slotMinTime: '08:00',
                slotMaxTime: '20:00',
                allDaySlot: false,
                events: this.events,
                editable: true,
                selectable: true,
                select: this.select,
                eventClick: this.eventClick,
                eventResize: this.eventResize,
                eventDrop: this.eventDrop,
            },
            selection: null,
        };
    },
    methods: {
        events(fetchInfo, successCallback, failureCallback) {
            const payload = (({ start, end }) => ({ start, end }))(fetchInfo);
            this.$store.dispatch('session/getList', payload).then(sessions => {
                successCallback(sessions.map(session => {
                    const start = dayjs(session.date).toDate();
                    const end = dayjs(session.date).add(session.duration, 'hour').toDate();
                    const title = session.description || '';
                    return {
                        id: session.id,
                        title,
                        start,
                        end,
                        session,
                    };
                }));
            });
        },
        select({ start, end }) {
            this.selection = {
                date: start,
                duration: (end-start)/3600000,
            };
        },
        unselect() {
            this.selection = null;
        },
        eventClick({ event }) {
            this.selection = { id: event.id };
        },
        eventResize({ event, revert }) {
            const duration = (event.end-event.start)/3600000;
            this.$store.dispatch('session/edit', { id: event.id, duration }).catch(revert);
        },
        eventDrop({ event, revert }) {
            const date = event.start.toISOString();
            this.$store.dispatch('session/edit', { id: event.id, date }).catch(revert);
        },
        refresh() {
            fcApi.refetchEvents();
        },
    },
    mounted() {
        fcApi = this.$refs.fullCalendar.getApi();
    }
};
