<template>
  <div>
    <h2>Blocs</h2>
    <block-edit
      v-if="blockEdited"
      :block-edited="blockEdited"
      @submit-success="submitSuccess"
      @close="close"
    ></block-edit>
    <button class="btn btn-secondary mb-2" @click="create">Créer</button>
    <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-4 g-2" v-if="blocks.length">
      <div
        role="button"
        class="col"
        v-for="block in blocks"
        :key="block.id"
        @click="open(block)"
      >
        <block :block="block" @refresh="refresh" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import block from "./Block";
import blockEdit from "./BlockEdit";

export default {
  components: { blockEdit, block },
  data() {
    return {
      blockEdited: null,
    };
  },
  computed: {
    ...mapGetters({
      blocks: "block/block",
    }),
  },
  methods: {
    refresh() {
      this.$store.dispatch("block/getList");
    },
    create() {
      this.blockEdited = {};
    },
    open(block) {
      this.blockEdited = block;
    },
    close() {
      this.blockEdited = null;
    },
    submitSuccess() {
      this.close();
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
