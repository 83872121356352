<template>
  <crud-card @remove="remove">
    {{ training.label }}
  </crud-card>
</template>

<script>
export default {
  props: ["training"],
  methods: {
    remove() {
      if (!confirm("Etes-vous sûr ?")) return;
      this.$store
        .dispatch("training/remove", this.training)
        .then(() => {
          this.$emit("refresh");
        });
    }
  }
};
</script>
