<style lang="scss">
@import "~flatpickr/dist/flatpickr.css";
@import "~@vueform/multiselect/themes/default.css";
</style>

<template>
  <modal @close="close">
    <template #header>
      <button type="button" class="btn close" @click="close">&times;</button>
      {{ id ? "Modifier" : "Créer" }} une session
    </template>
    <form id="session_form" @submit.prevent="submit">
      <div class="row g-3">
        <div class="col-sm-6">
          <label class="form-label" for="date">Date/heure</label>
          <flat-pickr
            class="form-control"
            v-model="date"
            :config="dateConfig"
            ref="date"
            id="date"
          />
        </div>
        <div class="col-sm-6">
          <label class="form-label" for="duration">Durée (h)</label>
          <time-picker ref="duration" v-model="duration" id="duration" />
        </div>
        <div>
          <label class="form-label" for="description">Description</label>
          <textarea
            class="form-control"
            id="description"
            rows="2"
            v-model="description"
          />
        </div>
        <div>
          <label class="form-label" for="new_trainee">Participants</label>
          <div class="d-flex mb-3">
            <select
              class="form-select"
              id="new_trainee"
              v-model="newTrainee"
              @change="addTrainee"
            >
              <option :value="null" disabled>Ajouter un participant</option>
              <option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.fullname }}
              </option>
            </select>
          </div>
          <div class="row g-3">
            <div
              v-for="trainee in trainees"
              :key="trainee"
              class="col-sm-6 d-flex align-items-center"
            >
              <span class="me-2" @click="delTrainee(trainee)">
                <fa icon="trash" />
              </span>
              {{ userFromId(trainee).fullname }}
            </div>
          </div>
        </div>
        <div>
          <label class="form-label">Programme</label>
          <div class="d-flex">
            <multiselect
              class="me-2"
              v-model="newWorkout.block"
              :options="blocksAll"
              :searchable="true"
              :createTag="true"
              valueProp="id"
              placeholder="Bloc"
              @select="selectBlock"
            />
            <select class="form-select" v-model="newWorkout.training">
              <option :value="null" disabled>Exercice</option>
              <option
                v-for="training in trainings"
                :key="training.id"
                :value="training.id"
              >
                {{ training.label }}
              </option>
            </select>
          </div>
          <div class="d-flex mt-2">
            <input
              class="form-control me-2"
              v-model="newWorkout.level"
              type="text"
              placeholder="Niveau (Reps, kg, ...)"
              @keydown.enter.prevent="addWorkout"
            />
            <button type="button" class="btn btn-dark" @click="addWorkout">
              Ajouter
            </button>
          </div>
          <div class="mt-2" v-for="blockout in blockouts" :key="blockout.id">
            <div>
              <u>{{ blockout.label }}</u>
              <span v-if="blockout.type" class="text-muted ms-1">{{
                blockout.type
              }}</span>
            </div>
            <div class="row row-cols-2 align-items-center">
              <div
                class="col"
                v-for="workout in blockout.workouts"
                :key="workout.id"
              >
                <div class="d-flex align-items-center">
                  <span class="me-2" @click="delWorkout(workout.workout)">
                    <fa icon="trash" />
                  </span>
                  <div>
                    <div>{{ workout.label }}</div>
                    <div v-if="workout.level" class="text-muted">
                      {{ workout.level }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!workouts.length" class="text-muted">Aucun</div>
        </div>
      </div>
    </form>
    <template #footer>
      <button type="button" class="btn btn-danger" v-if="id" @click="remove">
        Supprimer
      </button>
      <button type="button" class="btn btn-secondary ms-auto" @click="close">
        Annuler
      </button>
      <button type="submit" class="btn btn-primary ms-2" form="session_form">
        Valider
      </button>
    </template>
  </modal>
</template>

<script src="./SessionModal.js"></script>
