import { createStore } from 'vuex'
import auth from './auth.module';
import user from './user.module';
import hardware from './hardware.module';
import training from './training.module';
import session from './session.module';
import block from './block.module';

export default createStore({
  modules: {
    auth,
    user,
    hardware,
    training,
    session,
    block,
  }
});
