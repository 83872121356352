import store from '@/store';
import {userIsAdmin} from "./authorization";


function getUserAndProceed(proceed) {
    if (!store.getters['auth/user']) {
        store.watch(() => store.getters['auth/user'], function () {
            proceed();
        });
    } else {
        proceed();
    }
}


function requireAdminGuard(to, from, next) {
    getUserAndProceed(() => {
        const user = store.getters['auth/user'];
        if (!user) return next({ name: 'login' });
        if (!userIsAdmin(user)) return next(false);
        next();
    });
}


function pageTitleGuard(to, from) {
    const appName = 'MP';
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    document.title = appName + (nearestWithTitle ? ' - ' + nearestWithTitle.meta.title : '');
}


export {
    pageTitleGuard,
    requireAdminGuard,
}
