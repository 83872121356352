<style lang="scss">
@import "Planning";
</style>

<template>
  <div>
    <full-calendar :options="calendarOptions" ref="fullCalendar" />
    <session-modal
      v-if="selection"
      :selection="selection"
      @close="unselect"
      @submit="refresh"
    />
  </div>
</template>

<script src="./Planning.js"></script>
