<template>
  <form @submit.prevent="submit">
    <modal @close="$emit('close')">
      <template #header>
        <button type="button" class="btn close" @click="$emit('close')">
          &times;
        </button>
        {{ user.id ? "Modifier" : "Créer" }} un utilisateur
      </template>
      <div class="row g-3">
        <div class="col-md-6">
          <label class="form-label" for="firstname" ref="firstname">Prénom</label>
          <input
            class="form-control"
            id="firstname"
            type="text"
            v-model="user.firstname"
          />
        </div>
        <div class="col-md-6">
          <label class="form-label" for="lastname">Nom</label>
          <input
            class="form-control"
            id="lastname"
            type="text"
            v-model="user.lastname"
          />
        </div>
        <div class="col-md-6">
          <label class="form-label" for="email">Email</label>
          <input
            class="form-control"
            id="email"
            type="text"
            v-model="user.email"
          />
        </div>
        <div class="col-md-6">
          <label class="form-label">Rôle</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              id="roleUser"
              v-model="user.role"
              value="user"
            />
            <label class="form-check-label" for="roleUser">Élève</label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              id="roleAdmin"
              v-model="user.role"
              value="admin"
            />
            <label class="form-check-label" for="roleAdmin">Prof</label>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <form-error v-if="errors.length" :errors="errors"></form-error>
      </div>
      <template #footer>
        <button
          class="btn btn-secondary ms-auto"
          type="button"
          @click="$emit('close')"
        >
          Annuler
        </button>
        <button class="btn btn-primary ms-2" type="submit">Valider</button>
      </template>
    </modal>
  </form>
</template>

<script>
import FormError from "@/components/common/FormError";

export default {
  props: ["userEdited"],
  components: { FormError },
  data() {
    return {
      user: {
        id: null,
        firstname: "",
        lastname: "",
        role: "user",
        email: "",
      },
      errors: [],
    };
  },
  methods: {
    validate() {
      const required = ["firstname", "lastname", "role", "email"];
      const user = this.user;
      const missing = required.filter((field) => user[field].length <= 2);
      const missingError = missing.length !== 0;
      if (missingError) {
        this.errors.push("Champs manquants");
      }
      return !missingError;
    },
    submit() {
      this.errors = [];
      if (!this.validate()) return;
      this.$store
        .dispatch("user/edit", this.user)
        .then(() => {
          this.$emit("submit-success");
        })
        .catch((err) => {
          const errorMessage = err.response?.data?.message;
          if (errorMessage) this.errors.push(errorMessage);
        });
    },
  },
  mounted() {
    Object.assign(this.user, this.userEdited);
    this.$refs.firstname.focus();
  },
};
</script>
