<template>
  <div>
    <planning v-if="isAdmin">Accueil Admin</planning>
    <reservation v-if="isUser">Accueil Utilisateur</reservation>
    <div v-if="isNotLogged">Accueil Public</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Planning from "./planning/Planning.vue";
import Reservation from "./reservation/Reservation.vue";
import { userIsAdmin, userIsUser } from "@/utils/authorization";

export default {
  components: { Planning, Reservation },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    isAdmin() {
      return userIsAdmin(this.user);
    },
    isUser() {
      return userIsUser(this.user);
    },
    isNotLogged() {
      return !this.user;
    }
  },
}
</script>
