import fetch from '@/utils/fetch';
import { HARDWARE } from '@/config/api-routes';
import { LIST_HARDWARE } from './mutation-types';


const getDefaultState = () => {
    return {
        hardware: [],
    };
};
const state = getDefaultState();


const getters = {
    hardware: state => state.hardware,
};


const actions = {
    getList({ commit }) {
        return fetch(HARDWARE).then(response => {
            commit(LIST_HARDWARE, response.data);
        });
    },
    edit({ }, hardware) {
        return fetch(HARDWARE, { method: (hardware.id ? 'put' : 'post'), data: hardware });
    },
    remove({ }, hardware) {
        return fetch(HARDWARE, { method: 'delete', data: hardware });
    }
}


const mutations = {
    [LIST_HARDWARE](state, hardware) {
        state.hardware = hardware;
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
