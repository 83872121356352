<template>
  <crud-card @remove="remove">
    {{ block.label }}
  </crud-card>
</template>

<script>
export default {
  props: ["block"],
  methods: {
    remove() {
      if (!confirm("Etes-vous sûr ?")) return;
      this.$store.dispatch("block/remove", this.block).then(() => {
        this.$emit("refresh");
      });
    },
  },
};
</script>
