<template>
  <crud-card @remove="remove">
    {{ hardware.label }}
  </crud-card>
</template>

<script>
export default {
  props: ["hardware"],
  methods: {
    remove() {
      if (!confirm("Etes-vous sûr ?")) return;
      this.$store
        .dispatch("hardware/remove", this.hardware)
        .then(() => {
          this.$emit("refresh");
        });
    }
  }
};
</script>
