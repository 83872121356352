import { createWebHistory, createRouter } from 'vue-router';
import { requireAdminGuard, pageTitleGuard } from './utils/guards';
import Home from './components/Home';
import Login from './components/Login';
import Users from './components/users/Users';
import Hardwares from './components/hardware/Hardwares';
import Trainings from './components/training/Trainings';
import Blocks from './components/block/Blocks';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { title: 'Connexion' },
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        beforeEnter: requireAdminGuard,
        meta: { title: 'Utilisateurs' },
    },
    {
        path: '/admin/hardware',
        name: 'hardware',
        component: Hardwares,
        beforeEnter: requireAdminGuard,
        meta: { title: 'Matériel' },
    },
    {
        path: '/admin/training',
        name: 'training',
        component: Trainings,
        beforeEnter: requireAdminGuard,
        meta: { title: 'Exercices' },
    },
    {
        path: '/admin/block',
        name: 'block',
        component: Blocks,
        beforeEnter: requireAdminGuard,
        meta: { title: 'Blocks' },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(pageTitleGuard);

export default router;
