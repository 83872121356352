import fetch from '@/utils/fetch';
import { SESSION } from '@/config/api-routes';


const getDefaultState = () => {
    return {};
};
const state = getDefaultState();


const getters = {
};


const actions = {
    getList({ }, params) {
        return fetch(SESSION, { params }).then(response => response.data);
    },
    get({ }, id) {
        return fetch(SESSION + '/' + id).then(response => response.data);
    },
    edit({ }, session) {
        return fetch(SESSION, { method: (session.id ? 'put' : 'post'), data: session });
    },
    remove({ }, id) {
        return fetch(SESSION + '/' + id, { method: 'delete' });
    },
}


const mutations = {
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
