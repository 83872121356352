import getAxios from './axios';
import { setAuthorizationHeader, tokenIsExpired, refresh } from '@/services/auth.service';

const http = getAxios();
// let refreshTokenPromise = null;


// Loading

// const startLoading = (config) => {
//   if (config.url.indexOf('heartbeat') !== -1) return;
//   store.commit('App/loading/' + START_LOADING);
// };
// const finishLoading = (config) => {
//   if (config.url.indexOf('heartbeat') !== -1) return;
//   store.commit('App/loading/' + FINISH_LOADING);
// };

// http.interceptors.request.use(config => {
//   startLoading(config);
//   return config;
// }, error => {
//   finishLoading(error.config);
//   return Promise.reject(error);
// });
// http.interceptors.response.use(response => {
//   finishLoading(response.config);
//   return response.data;
// }, error => {
//   finishLoading(error.config);
//   return Promise.reject(error);
// });


// JWT refresh

http.interceptors.request.use(config => {
  if (!tokenIsExpired()) return config;
  return refresh().then(() => {
    setAuthorizationHeader(config);
    return Promise.resolve(config);
  });
}, (error) => {
  return Promise.reject(error);
});


function fetch(route, config = {}) {
  config.url = route;
  setAuthorizationHeader(config);
  return http.request(config);
}


export default fetch;
