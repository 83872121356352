<template>
  <div>
    <h2>Matériel</h2>
    <hardware-edit
      v-if="hardwareEdited"
      :hardware-edited="hardwareEdited"
      @submit-success="submitSuccess"
      @close="close"
    ></hardware-edit>
    <button class="btn btn-secondary mb-2" @click="create">Créer</button>
    <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-4 g-2" v-if="hardwares.length">
      <div
        role="button"
        class="col"
        v-for="hardware in hardwares"
        :key="hardware.id"
        @click="open(hardware)"
      >
        <hardware :hardware="hardware" @refresh="refresh" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Hardware from "./Hardware";
import HardwareEdit from "./HardwareEdit";

export default {
  components: { HardwareEdit, Hardware },
  data() {
    return {
      hardwareEdited: null,
    };
  },
  computed: {
    ...mapGetters({
      hardwares: "hardware/hardware",
    }),
  },
  methods: {
    refresh() {
      this.$store.dispatch("hardware/getList");
    },
    create() {
      this.hardwareEdited = {};
    },
    open(hardware) {
      this.hardwareEdited = hardware;
    },
    close() {
      this.hardwareEdited = null;
    },
    submitSuccess() {
      this.close();
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
