<template>
  <div>
    <h2>Exercices</h2>
    <training-edit
      v-if="trainingEdited"
      :training-edited="trainingEdited"
      @submit-success="submitSuccess"
      @close="close"
    ></training-edit>
    <button class="btn btn-secondary mb-2" @click="create">Créer</button>
    <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-4 g-2" v-if="trainings.length">
      <div
        role="button"
        class="col"
        v-for="training in trainings"
        :key="training.id"
        @click="open(training)"
      >
        <training :training="training" @refresh="refresh" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Training from "./Training";
import TrainingEdit from "./TrainingEdit";

export default {
  components: { TrainingEdit, Training },
  data() {
    return {
      trainingEdited: null,
    };
  },
  computed: {
    ...mapGetters({
      trainings: "training/training",
    }),
  },
  methods: {
    refresh() {
      this.$store.dispatch("training/getList");
    },
    create() {
      this.trainingEdited = {};
    },
    open(training) {
      this.trainingEdited = training;
    },
    close() {
      this.trainingEdited = null;
    },
    submitSuccess() {
      this.close();
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
