<template>
  <form @submit.prevent="submit">
    <modal @close="$emit('close')">
      <template #header>
        <button type="button" class="btn close" @click="$emit('close')">
          &times;
        </button>
        {{ training.id ? "Modifier" : "Créer" }} un matériel
      </template>
      <div class="mb-3">
        <label class="form-label" for="label" ref="label">Nom</label>
        <input
          class="form-control"
          id="label"
          type="text"
          v-model="training.label"
        />
      </div>
      <div>
        <label class="form-label" for="hardware" ref="label">Matériel</label>
        <select class="form-select" id="hardware" v-model="training.hardware">
          <option value=""></option>
          <option v-for="hardware in hardwares" :key="hardware.id" :value="hardware.id">{{ hardware.label }}</option>
        </select>
      </div>
      <div class="d-flex align-items-center">
        <form-error v-if="errors.length" :errors="errors"></form-error>
      </div>
      <template #footer>
        <button
          class="btn btn-secondary ms-auto"
          type="button"
          @click="$emit('close')"
        >
          Annuler
        </button>
        <button class="btn btn-primary ms-2" type="submit">Valider</button>
      </template>
    </modal>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import FormError from "@/components/common/FormError";

export default {
  props: ["trainingEdited"],
  components: { FormError },
  data() {
    return {
      training: {
        id: null,
        label: "",
      },
      errors: [],
    };
  },
  computed: {
    ...mapGetters({
      hardwares: "hardware/hardware",
    }),
  },
  methods: {
    validate() {
      const required = ["label"];
      const training = this.training;
      const missing = required.filter((field) => training[field].length <= 2);
      const missingError = missing.length !== 0;
      if (missingError) {
        this.errors.push("Champs manquants");
      }
      return !missingError;
    },
    submit() {
      this.errors = [];
      if (!this.validate()) return;
      this.$store
        .dispatch("training/edit", this.training)
        .then(() => {
          this.$emit("submit-success");
        })
        .catch((err) => {
          const errorMessage = err.response?.data?.message;
          if (errorMessage) this.errors.push(errorMessage);
        });
    },
  },
  mounted() {
    this.$store.dispatch("hardware/getList");
    Object.assign(this.training, this.trainingEdited);
    this.$refs.label.focus();
  },
};
</script>
