<template>
  <div class="card">
    <div class="d-flex">
      <div class="p-3 bg-light rounded-start border-end" @click.stop="$emit('remove')">
        <fa icon="trash" class="ms-1" />
      </div>
      <div class="p-3">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>
