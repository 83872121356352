<template>
  <crud-card @remove="remove">
    {{ user.fullname }}
  </crud-card>
</template>

<script>
export default {
  props: ["user"],
  methods: {
    remove() {
      if (!confirm("Etes-vous sûr ?")) return;
      this.$store
        .dispatch("user/remove", this.user)
        .then(() => {
          this.$emit("refresh");
        });
    }
  }
};
</script>
