<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-dark">
    <div class="container">
      <router-link class="navbar-brand" :to="{ name: 'home' }">MP</router-link>
      <button
        :class="['navbar-toggler', { collapsed: !showMenu }]"
        type="button"
        @click="showMenu = !showMenu"
        aria-controls="navbarNav"
        :aria-expanded="showMenu"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div :class="['navbar-collapse', 'collapse', { show: showMenu }]">
        <ul class="navbar-nav me-auto">
          <li class="nav-item" v-if="isAdmin">
            <router-link
              class="nav-link"
              active-class="active"
              :to="{ name: 'users' }"
              >Utilisateurs</router-link
            >
          </li>
          <li class="nav-item" v-if="isAdmin">
            <router-link
              class="nav-link"
              active-class="active"
              :to="{ name: 'training' }"
              >Exercices</router-link
            >
          </li>
          <li class="nav-item" v-if="isAdmin">
            <router-link
              class="nav-link"
              active-class="active"
              :to="{ name: 'hardware' }"
              >Matériel</router-link
            >
          </li>
          <li class="nav-item" v-if="isAdmin">
            <router-link
              class="nav-link"
              active-class="active"
              :to="{ name: 'block' }"
              >Blocs</router-link
            >
          </li>
        </ul>
        <span class="navbar-text text-muted" v-if="authenticated">{{
          user.firstname
        }}</span>
        <ul class="navbar-nav">
          <li class="nav-item" v-if="authenticated" @click="logout">
            <a href="#" class="nav-link">Déconnexion</a>
          </li>
          <li class="nav-item" v-if="!authenticated">
            <router-link class="nav-link" :to="{ name: 'login' }"
              >Connexion</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import { userIsAdmin } from "@/utils/authorization";

export default {
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    isAdmin() {
      return userIsAdmin(this.user);
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: "login" });
    },
  },
};
</script>
