import { mapGetters } from "vuex";
import FlatPickr from 'vue-flatpickr-component';
import { French } from "flatpickr/dist/l10n/fr.js";
import Multiselect from '@vueform/multiselect';
import TimePicker from '@/components/common/TimePicker.vue';
// import IconRemove from '@/assets/img/minus-square-solid.svg';

const getDefaultData = () => {
    return {
        id: null,
        date: null,
        duration: null,
        trainees: [],
        location: null,
        description: null,
        workouts: [],
        dateConfig: {
            enableTime: true,
            dateFormat: 'Y-m-d H:i',
            locale: French,
        },
        newWorkout: {
            block: null,
            training: null,
            level: '',
        },
        newTrainee: null,
    };
};

export default {
    props: ["selection"],
    components: {
        FlatPickr,
        TimePicker,
        Multiselect,
        // IconRemove,
    },
    data: getDefaultData,
    computed: {
        ...mapGetters({
            usersAll: "user/users",
            trainingsAll: "training/training",
            blocksAll: "block/block",
        }),
        users() {
            return this.usersAll
                .filter(user => user.role === "user" && !this.trainees.find(trainee => user.id === trainee))
                .sort((a, b) => {
                    if (a.fullname > b.fullname) return 1;
                    if (a.fullname < b.fullname) return -1;
                    return 0;
                });
        },
        trainings() {
            return this.trainingsAll.sort((a, b) => {
                if (a.label > b.label) return 1;
                if (a.label < b.label) return -1;
                return 0;
            });
        },
        blockouts() { // Grouping workouts by blocks
            if (!this.workouts.length) return [];
            const indexedBlockouts = this.workouts.reduce((accu, cur, index) => {
                const block = this.blocksAll.find(block => block.id === cur.block);
                accu[cur.block] = accu[cur.block]
                    || {
                        label: cur.block && block ? block.label : '',
                        type: cur.block && block ? block.type : null,
                        workouts: []
                    };
                const training = this.trainingsAll.find(training => cur.training === training.id);
                accu[cur.block].workouts.push({
                    index,
                    label: training ? training.label : cur.training,
                    level: cur.level,
                    workout: cur,
                });
                return accu;
            }, Object.create(null));
            // const orderedBlockouts = Object.values(indexedBlockouts).sort((a, b) => {
            //     if (a.label > b.label) return 1;
            //     if (a.label < b.label) return -1;
            //     return 0;
            // });
            // console.log(orderedBlockouts);
            return Object.values(indexedBlockouts);
        },
    },
    methods: {
        loadFromId(id) {
            this.$store.dispatch('session/get', id).then(session => {
                this.id = id;
                this.date = session.date;
                this.duration = session.duration;
                this.description = session.description;
                this.trainees = session.trainees;
                this.workouts = session.workouts;
            });
        },
        loadFromSelection(selection) {
            if (selection.id) return this.loadFromId(selection.id);
            if (selection.date) this.date = selection.date;
            if (selection.duration) this.duration = selection.duration;
        },
        userFromId(userId) {
            return this.usersAll.find(user => user.id === userId);
        },
        addTrainee() {
            this.trainees.push(this.newTrainee);
            this.newTrainee = null;
        },
        delTrainee(trainee) {
            this.trainees.splice(this.trainees.indexOf(trainee), 1);
        },
        selectBlock(blockLabel) {
            if (this.blocksAll.find(block => block.label === blockLabel)) return;
            this.blocksAll.push({ id: blockLabel, label: blockLabel });
        },
        addWorkout() {
            if (!this.newWorkout.training) return;
            this.workouts.push(this.newWorkout);
            this.newWorkout = getDefaultData().newWorkout;
        },
        delWorkout(workout) {
            this.workouts.splice(this.workouts.indexOf(workout), 1);
        },
        submit() {
            const session = {
                id: this.id,
                date: this.$refs.date.fp.selectedDates[0].toISOString(),
                duration: this.duration,
                description: this.description,
                trainees: this.trainees,
                workouts: this.workouts,
            };
            this.$store.dispatch('session/edit', session).then(() => {
                this.$emit('submit');
                this.close();
            });
        },
        remove() {
            if (!this.id) return;
            if (!confirm('Êtes-vous sur de vouloir supprimer cette session ?')) return;
            this.$store.dispatch('session/remove', this.id).then(() => {
                this.$emit('submit');
                this.close();
            });
        },
        close() {
            this.$emit('close');
        },
    },
    mounted() {
        this.$store.dispatch('user/getList');
        this.$store.dispatch('training/getList');
        this.$store.dispatch('block/getList');
        this.loadFromSelection(this.selection);
        this.$refs.duration.focus();
    },
};
