import fetch from '@/utils/fetch';
import { USERS } from '@/config/api-routes';
import { LIST_USERS } from './mutation-types';


const getDefaultState = () => {
    return {
        users: [],
    };
};
const state = getDefaultState();


const getters = {
    users: state => state.users,
};


const actions = {
    getList({ commit }) {
        return fetch(USERS).then(response => {
            commit(LIST_USERS, response.data);
        });
    },
    edit({ }, user) {
        return fetch(USERS, { method: (user.id ? 'put' : 'post'), data: user });
    },
    remove({ }, user) {
        return fetch(USERS, { method: 'delete', data: user });
    }
}


const mutations = {
    [LIST_USERS](state, users) {
        state.users = users;
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
