<template>
  <div>
    <div class="card mx-auto col-md-4">
      <form class="card-body" @submit.prevent="handleLogin">
        <h5 class="card-title">Connexion</h5>
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input id="email" class="form-control" type="text" v-model="user.email" />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Mot de passe</label>
          <input id="password" class="form-control" type="password" v-model="user.password" />
        </div>
        <button type="submit" class="btn btn-primary">Login</button>
      </form>
      <form-error v-if="errors.length" :errors="errors" />
    </div>
  </div>
</template>

<script>
import FormError from "@/components/common/FormError";

export default {
  components: {
    FormError,
  },
  data() {
    return {
      user: { email: null, password: null },
      errors: [],
    };
  },
  methods: {
    handleLogin() {
      this.errors = [];
      if (!this.user.email || !this.user.password) return;
      this.$store
        .dispatch("auth/login", this.user)
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch((err) => {
          this.errors.push(err.response.data.message);
        });
    },
  },
};
</script>
