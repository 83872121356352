import fetch from '@/utils/fetch';
import { TRAINING } from '@/config/api-routes';
import { LIST_TRAINING } from './mutation-types';


const getDefaultState = () => {
    return {
        training: [],
    };
};
const state = getDefaultState();


const getters = {
    training: state => state.training,
};


const actions = {
    getList({ commit }) {
        return fetch(TRAINING).then(response => {
            commit(LIST_TRAINING, response.data);
        });
    },
    edit({ }, training) {
        return fetch(TRAINING, { method: (training.id ? 'put' : 'post'), data: training });
    },
    remove({ }, training) {
        return fetch(TRAINING, { method: 'delete', data: training });
    }
}


const mutations = {
    [LIST_TRAINING](state, training) {
        state.training = training;
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
