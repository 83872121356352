<template>
  <form @submit.prevent="submit">
    <modal @close="$emit('close')">
      <template #header>
        <button type="button" class="btn close" @click="$emit('close')">
          &times;
        </button>
        {{ block.id ? "Modifier" : "Créer" }} un bloc
      </template>
      <div class="row g-3">
        <div class="col-md-6">
          <label class="form-label" for="label" ref="label">Nom</label>
          <input
            class="form-control"
            id="label"
            type="text"
            v-model="block.label"
          />
        </div>
        <div class="col-md-6">
          <label class="form-label">Type</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              id="typeEMOM"
              v-model="block.type"
              value="EMOM"
            />
            <label class="form-check-label" for="typeEMOM">EMOM</label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              id="typeAMRAP"
              v-model="block.type"
              value="AMRAP"
            />
            <label class="form-check-label" for="typeAMRAP">AMRAP</label>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <form-error v-if="errors.length" :errors="errors"></form-error>
      </div>
      <template #footer>
        <button
          class="btn btn-secondary ms-auto"
          type="button"
          @click="$emit('close')"
        >
          Annuler
        </button>
        <button class="btn btn-primary ms-2" type="submit">Valider</button>
      </template>
    </modal>
  </form>
</template>

<script>
import FormError from "@/components/common/FormError";

export default {
  props: ["blockEdited"],
  components: { FormError },
  data() {
    return {
      block: {
        id: null,
        label: "",
        type: null,
      },
      errors: [],
    };
  },
  methods: {
    validate() {
      const required = ["label"];
      const block = this.block;
      const missing = required.filter((field) => block[field].length <= 2);
      const missingError = missing.length !== 0;
      if (missingError) {
        this.errors.push("Champs manquants");
      }
      return !missingError;
    },
    submit() {
      this.errors = [];
      if (!this.validate()) return;
      this.$store
        .dispatch("block/edit", this.block)
        .then(() => {
          this.$emit("submit-success");
        })
        .catch((err) => {
          const errorMessage = err.response?.data?.message;
          if (errorMessage) this.errors.push(errorMessage);
        });
    },
  },
  mounted() {
    Object.assign(this.block, this.blockEdited);
    this.$refs.label.focus();
  },
};
</script>
