<template>
  <div>
    <h2>Utilisateurs</h2>
    <user-edit
      v-if="userEdited"
      :user-edited="userEdited"
      @submit-success="submitSuccess"
      @close="close"
    ></user-edit>
    <button class="btn btn-secondary mb-2" @click="create">Créer</button>
    <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-4 g-2" v-if="users.length">
      <div
        role="button"
        class="col"
        v-for="user in users"
        :key="user.id"
        @click="open(user)"
      >
        <user :user="user" @refresh="refresh" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserEdit from "./UserEdit";
import User from "./User";

export default {
  components: { UserEdit, User },
  data() {
    return {
      userEdited: null,
    };
  },
  computed: {
    ...mapGetters({
      users: "user/users",
    }),
  },
  methods: {
    refresh() {
      this.$store.dispatch("user/getList");
    },
    create() {
      this.userEdited = {};
    },
    open(user) {
      this.userEdited = user;
    },
    close() {
      this.userEdited = null;
    },
    submitSuccess() {
      this.close();
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
