<style lang="scss">
@import "./TimePicker";
</style>

<template>
    <div :class="['timepicker', {'is-invalid': error}]">
        <input type="number" min="0" max="23" v-model="hour" ref="hour" :id="id"> :
        <input type="number" min="0" max="45" step="15" v-model="minute">
    </div>
</template>

<script>
export default {
    props: [ "modelValue", "error", "id" ],
    computed: {
        hour: {
            get() {
                return this.modelValue ? Math.floor(this.modelValue) : 0;
            },
            set(hour) {
                this.updateHours(hour, this.minute);
            },
        },
        minute: {
            get() {
                return this.modelValue ? Math.round((this.modelValue % 1) * 60) : 0;
            },
            set(minute) {
                this.updateHours(this.hour, minute);
            },
        }
    },
    methods: {
        updateHours(hour, minute) {
            const value = parseInt(hour) + (parseInt(minute) / 60);
            this.$emit('update:modelValue', value);
        },
        focus() {
            const hourInput = this.$refs.hour;
            hourInput.focus();
            // v-- Not supported on number type inputs for certain browsers --v
            // hourInput.setSelectionRange(0, hourInput.value.length);
        },
    },
};
</script>
