import { createApp } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faEdit,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from './router';
import store from './store';
import App from './App.vue'
import { ClickOutside } from './utils/vue-additions';
import Modal from './components/common/Modal';
import CrudCard from './components/common/CrudCard';

library.add({
    faEdit,
    faTrash,
});

const app = createApp(App);
app.use(router);
app.use(store);

app.directive('click-outside', ClickOutside);
app.component('Modal', Modal);
app.component('fa', FontAwesomeIcon);
app.component('CrudCard', CrudCard);

app.mount('#app');
