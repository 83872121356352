function userIsAdmin(user) {
    if (!user) return false;
    return user.role === 'admin';
}

function userIsUser(user) {
    if (!user) return false;
    return user.role === 'user';
}


export {
    userIsAdmin,
    userIsUser,
}
