import getAxios from '../utils/axios';
import jsonDecode from 'jwt-decode';
import { LOGIN, REFRESH, LOGOUT } from '@/config/api-routes';


const http = getAxios();
http.defaults.withCredentials = true;


let user = null;


function getUser() {
    return user;
}


function storeJWT(response) {
    if (!response.data.accessToken) return null;
    return user = response.data;
}


function getJWT() {
    if (!user) return null;
    return user.accessToken;
}


function login(user) {
    const data = {
        email: user.email,
        password: user.password
    };
    return http.post(LOGIN, data).then(storeJWT);
}


function tokenIsExpired() {
    if (!user?.accessToken) return true;
    const exp = jsonDecode(user.accessToken).exp;
    return (Date.now() / 1000) > exp - 5; // Considering the token is expired 5s before the real expiration
}


function refresh() {
    return http.post(REFRESH).then(storeJWT);
}


function setAuthorizationHeader(config) {
    config.headers = config.headers || {};
    config.headers['Authorization'] = 'Bearer ' + getJWT();
}


function logout() {
    const config = {
        method: 'POST',
        url: LOGOUT,
    };
    setAuthorizationHeader(config);
    return http.request(config).then(() => {
        user = null;
    });
}


export {
    getJWT,
    getUser,
    login,
    logout,
    refresh,
    setAuthorizationHeader,
    tokenIsExpired,
};
