import { login, refresh, logout, getUser } from '@/services/auth.service';
import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from './mutation-types';


const getDefaultState = () => {
    return {
        user: getUser(),
    };
};
const state = getDefaultState();


const getters = {
    user: state => state.user,
    authenticated: state => !!state.user,
};


const actions = {
    login({ commit }, user) {
        return login(user).then(user => {
            commit(LOGIN_SUCCESS, user);
        });
    },
    refresh({ commit }) {
        return refresh().then(user => {
            commit(LOGIN_SUCCESS, user);
        });
    },
    logout({ commit }) {
        return logout().then(() => {
            commit(LOGOUT);
        });
    },
}


const mutations = {
    [LOGIN_SUCCESS](state, user) {
        user.name = `${user.firstname} ${user.lastname}`;
        state.user = user;
    },
    [LOGIN_FAILURE](state) {
        state.user = null;
    },
    [LOGOUT](state) {
        state.user = null;
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
