<template>
  <div class="invalid-feedback d-block">
    <div v-for="error in errors" :key="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: "FormError",
  props: ["errors"],
};
</script>
