<style lang="scss">
@import "./custom-bootstrap";
@import "./app";
</style>


<template>
  <div id="app">
    <nav-bar />
    <router-view class="container pt-4" />
  </div>
</template>


<script>
import NavBar from "./components/NavBar";

export default {
  name: "App",
  components: { NavBar },
  mounted() {
    this.$store.dispatch("auth/refresh");
  },
};
</script>
