export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

export const LIST_USERS = 'LIST_USERS';
export const LIST_HARDWARE = 'LIST_HARDWARE';
export const LIST_TRAINING = 'LIST_TRAINING';

export const LIST_BLOCKS = 'LIST_BLOCKS';
