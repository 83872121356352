<style lang="scss">
@import "Modal";
</style>

<template>
  <div class="modal_overlay" ref="overlay" @click="$emit('close')">
    <div
      :class="['modal', { modal_large: large }]"
      @keyup.escape="$emit('close')"
      @click.stop
    >
      <div v-if="$slots.header" class="modal_header">
        <slot name="header"></slot>
      </div>
      <div :class="['modal_body', { 'p-0': bodynopadding }]">
        <slot></slot>
      </div>
      <div v-if="$slots.footer" class="modal_footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bodynopadding: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const body = document.body;
    const scrollbarWidth = window.innerWidth - body.clientWidth;
    body.classList.add("modalopen");
    body.style.paddingRight = scrollbarWidth + "px";
  },
  unmounted() {
    // // Would be better with after-leave transition hook but didn't work
    // setTimeout(() => {
      const body = document.body;
      body.classList.remove("modalopen");
      body.style.paddingRight = 0;
    // }, 100);
  },
};
</script>
