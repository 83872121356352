import fetch from '@/utils/fetch';
import { BLOCKS } from '@/config/api-routes';
import { LIST_BLOCKS } from './mutation-types';


const getDefaultState = () => {
    return {
        block: [],
    };
};
const state = getDefaultState();


const getters = {
    block: state => state.block,
};


const actions = {
    getList({ commit }) {
        return fetch(BLOCKS).then(response => {
            commit(LIST_BLOCKS, response.data);
        });
    },
    edit({ }, block) {
        return fetch(BLOCKS, { method: (block.id ? 'put' : 'post'), data: block });
    },
    remove({ }, block) {
        return fetch(BLOCKS, { method: 'delete', data: block });
    }
}


const mutations = {
    [LIST_BLOCKS](state, block) {
        state.block = block;
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
