import axios from 'axios';

export default function getAxios() {
    return axios.create({
        baseURL: process.env.VUE_APP_API_HOST,
        headers: {
            'Accept': 'application/json',
        }
    });
}
